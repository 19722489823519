<template>
  <div class="contents" v-loading="loading">
    <div class="title flexB">
      <h2>단어 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="word">한글 단어</option>
        </select>
        <input type="text" v-model="searchWord" />
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <div class="flexB mb20">
        <el-select v-model="category">
          <el-option label="전체" value=""> </el-option>
          <el-option
            v-for="(cat, i) in categories"
            :key="i"
            :label="cat"
            :value="cat"
          >
          </el-option>
        </el-select>

        <button class="right line2">
          <router-link to="/admin/word/register">단어 등록</router-link>
        </button>
      </div>

      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 15%" />
          <col style="width: 20%" />
          <col style="width: 10%" />
          <col style="width: 30%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>카테고리</th>
          <th>한글단어</th>
          <th>품사</th>
          <th>예문</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in wordList" :key="i">
          <td>
            <!-- {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}  -->
            {{ wordList.length - i }}
          </td>
          <td>{{ data.category }}</td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.word
            }}</router-link>
          </td>
          <td>{{ data.parts }}</td>
          <td>{{ data.description[0]?.desc_kr }}</td>
          <td>
            {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm:ss") }}
          </td>
        </tr>
      </table>
      <div class="pagination" v-if="wordList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="20"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchWordList } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";

import category from "@/config/wordCategory.json";

export default {
  mixins: [format],
  components: { NoData },
  name: "wordManage",
  data() {
    return {
      moment: moment,
      loading: false,
      inputMode: "",
      searchWord: "",
      wordList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      category: "",
      categories: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  mounted() {
    this.loading = true;
    this.categories = category.categories;

    this.getWordList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleMove(id) {
      this.$router.push({ name: "wordRegister", query: { id: id } });
    },

    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.wordList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getWordList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        return alert("검색 조건을 선택해 주세요.");
      }
      this.getWordList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getWordList();
    },
    getWordList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
      };
      fetchWordList(params).then((res) => {
        console.log("word >>>", res);
        if (res.data.status == 200) {
          this.wordList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.loading = false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
